<template>
	<v-sheet class="create-claims create-page" id="claims">
		
	
	<!-- fdgdg -->
		<div class="px-3">
			<v-row class="sticky">
				<v-col md="6" class="my-auto">
					<h1 class="custom-header-blue-text m-0">{{ pageTitle() }}  </h1>
				</v-col>
				<v-col md="6" class="text-right">
					<v-btn
						:loading="pageLoading"
						:disabled="pageLoading"
						class="white--text mr-2"
						depressed
						color="blue darken-4"
						tile
						@click="createOrUpdate"
					>
						Save
					</v-btn>
					<v-btn :disabled="pageLoading" depressed tile v-on:click="goBackListing"> Cancel </v-btn>
				</v-col>
			</v-row>
			<v-form
				ref="claimForm"
				id="claimForm"
				v-model.trim="formValid"
				lazy-validation
				@submit.stop.prevent="createOrUpdate"
			>
				<v-row>
					<v-col cols="9">
						<!-- <div class="form-grp-title">
							<v-row>
								<v-col cols="12" lg="12" class="">
									<v-row class="mb-4">
										<v-col lg="12" class="my-auto py-0">
											<v-radio-group v-model="claim.category" row hide-details class="mt-0">
												<v-radio label="Own" value="owan"></v-radio>
												<v-radio label="Customer" value="customer"></v-radio>
											</v-radio-group>
										</v-col>
									</v-row>
								</v-col>
							</v-row>
						</div> -->
						<div class="form-grp-title">
							<v-row>
								<v-col cols="12" lg="12" class="">
									<RadioInputCustom
										v-model="claim.claim_type"
										row
										color="blue darken-4"
										:items="[
											{
												label: 'Lead',
												value: 'lead',
											},
											{
												label: 'Customer',
												value: 'customer',
											},
											{
												label: 'Other',
												value: 'other',
											},
										]"
										@change="filterList('meeting')"
									/>
								</v-col>
							</v-row>
						</div>
						<div class="form-grp-title">New Expense</div>
						<v-row class="px-4">
							<v-col cols="12" lg="12" class="">
								<v-row class="mb-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label d-flex align-center">
											Claim Month
											<div class="ml-3 d-flex align-center" v-if="disableClaimMonth">
												<v-tooltip top content-class="custom-top-tooltip">
													<template #activator="{ on, attrs }">
														<v-icon v-on="on" v-bind="attrs" size="17" class="mr-2">
															mdi-information-outline
														</v-icon>
													</template>
													<em>
														<span>
															{{ this.claimId > 0 ? "Added " : "Today's " }} Date must be 07 or less to
															select</span
														>&NonBreakingSpace;
														<span class="fw-500">Previous</span>
													</em>
												</v-tooltip>
											</div>
										</label>
									</v-col>
									<v-col lg="8" class="my-auto py-0">
										<div class="d-flex align-center">
											<v-radio-group
												v-model="claim.claim_month"
												@change="getCategoryOptions($event)"
												row
												hide-details
												class="mt-0 fw-500"
											>
												<v-radio :disabled="disableClaimMonth" value="previous" :ripple="false">
													<template #label>
														<span :class="[{ 'text-muted fw-500': disableClaimMonth }]" style="font-size: 13px"
															>Previous {{currentUser.id}}</span
														></template
													>
												</v-radio>
												<v-radio label="Current" :ripple="false" value="current"></v-radio>
											</v-radio-group>
										</div>
									</v-col>
								</v-row>
							
								<v-row class="mb-4" v-if="currentUser.id ==35">


									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label required">Assign To </label>
									</v-col>
								
									<v-col lg="8" class="my-auto py-0 position-relative">
										<v-autocomplete
											:items="userList"
											v-model="claim.assign"
											item-text="first_name"
											item-value="id"
											depressed
											@change="getUser()"
										
											placeholder="Assigned To"
											hide-details
											outlined
											hide-selected
											class="cuatom-drop"
											color="blue darken-4"
											small-chips
											deletable-chips
											:rules="[vrules.required(claim.assign, 'PAssigned To')]"
											:class="{
												required: !claim.assign,
											}"
										>
											<template #selection="data">
												<v-chip small v-bind="data.attrs">
													<v-avatar left>
														<img v-if="data.item.profile_img" :src="data.item.profile_img" />
														<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
													</v-avatar>

													<span class="text-uppercase fw-500">{{ data.item.display_name }}</span>
												</v-chip>
											</template>
											<template #item="data">
												<div class="py-1 d-flex align-center justify-space-between w-100">
													<div class="d-flex align-center">
														<v-avatar size="38">
															<img v-if="data.item.profile_img" :src="data.item.profile_img" />
															<img
																v-else
																src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
															/>
														</v-avatar>
														<div class="ms-2">
															<div class="fw-600 text-uppercase">{{ data.item.display_name }}</div>
															<div class="fw-600 text-muted">{{ data.item.email }}</div>
														</div>
													</div>

													<!-- <v-chip x-small label dark class="px-1" color="red">
														<span class="fw-500 fs-11 text-uppercase">{{ data.item.users_type }}</span>
													</v-chip> -->
												</div>
											</template>
										</v-autocomplete>
										<!-- 	<v-autocomplete
											hide-details
											:items="userList"
											placeholder="Assign To"
											outlined
											v-model="create_lead.assign"
											item-text="first_name"
											item-value="id"
											class=""
										>
										</v-autocomplete> -->
										<!-- <ManageAutocomplete title="Assign" v-on:update="getSettings"></ManageAutocomplete> -->
									</v-col>
								</v-row>
								<v-row class="mb-4" v-if="currentUser.id ==35 && !$route.params.id">
									
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Claim Date</label>
									</v-col>
									<v-col lg="8" class="my-auto py-0">
										<DatePicker
											hide-details
											clearable
											:disabled="pageLoading"
											:loading="pageLoading"
											id="expense-date"
											@change="getUser()"
											:max-date="maxDate"
											placeholder="Claim Date"
											v-model="claim.claim_date"
										
										></DatePicker>
									</v-col>
								</v-row>
							
								<v-row class="mb-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Company Name</label>
									</v-col>
									<v-col lg="8" class="my-auto py-0">
										<!-- v-if="claim.claim_type!='lead'" -->
										<v-autocomplete
											:items="customerList"
											item-text="contact_name"
											item-value="id"
											hide-details
											outlined
											placeholder="Company"
											v-model="claim.customer"
											:disabled="pageLoading"
											:loading="pageLoading"
											@change="filterList('project')"
										>
											<template v-slot:item="data">
												<v-list-item-avatar size="30" class="grey lighten-2">
													<template v-if="data.item.image">
														<v-img :src="data.item.image"></v-img>
													</template>
													<template v-else>
														<span>{{ data.item.contact_name.slice(0, 2) }}</span>
													</template>
												</v-list-item-avatar>
												<v-list-item-content>
													<v-list-item-title>
														{{ data.item.contact_name }}
													</v-list-item-title>
													<v-list-item-subtitle class="pb-2">
														Company - <span class="black--text">{{ data.item.company_name }}</span>
													</v-list-item-subtitle>
												</v-list-item-content>
											</template>
										</v-autocomplete>

										<!-- <v-autocomplete
											v-else
											:items="leadList"
											item-text="contact_name"
											item-value="id"
											hide-details
											outlined
											placeholder="Company"
											v-model="claim.customer"
											:disabled="pageLoading"
											:loading="pageLoading"
											@change="filterList('project')"
										>
											<template v-slot:item="data">
												<v-list-item-avatar size="30" class="grey lighten-2">
													<template v-if="data.item.image">
														<v-img :src="data.item.image"></v-img>
													</template>
													<template v-else>
														<span>{{ data.item.contact_name.slice(0, 2) }}</span>
													</template>
												</v-list-item-avatar>
												<v-list-item-content>
													<v-list-item-title>
														{{ data.item.contact_name }}
													</v-list-item-title>
													<v-list-item-subtitle class="pb-2">
														Company - <span class="black--text">{{ data.item.company_name }}</span>
													</v-list-item-subtitle>
												</v-list-item-content>
											</template>
										</v-autocomplete> -->
									</v-col>
								</v-row>
								<v-row class="mb-4" v-if="claim.claim_type == 'customer'">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Project</label>
									</v-col>
									<v-col lg="8" class="my-auto py-0">
										<v-autocomplete
											:items="projectList"
											item-text="text"
											item-value="value"
											hide-details
											outlined
											placeholder="Project"
											v-model="claim.project"
											:disabled="pageLoading"
											:loading="pageLoading"
										>
										</v-autocomplete>
									</v-col>
								</v-row>
								<v-row class="mb-4" v-if="claim.claim_type != 'other'">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Meeting</label>
									</v-col>
									<v-col lg="8" class="my-auto py-0">
										<v-autocomplete
											:items="meetingList"
											item-text="text"
											item-value="value"
											hide-details
											outlined
											placeholder="Meeting"
											v-model="claim.meeting"
											:disabled="pageLoading"
											:loading="pageLoading"
										>
											<template v-slot:item="data">
												<v-list-item-content class="border-bottom py-2">
													<v-list-item-title>
														{{ data.item.title }} - {{ data.item.barcode }}
													</v-list-item-title>
													<v-list-item-subtitle>
														{{ formatDate(data.item.start_from) }} {{ data.item.start_time }} ({{
															data.item.duration
														}})
													</v-list-item-subtitle>
												</v-list-item-content>
											</template>
										</v-autocomplete>
									</v-col>
								</v-row>
								<v-row class="pb-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label"> Reference # </label>
									</v-col>
									<v-col lg="8" class="my-auto py-0">
										<v-text-field
											hide-details
											outlined
											placeholder="Reference"
											v-model="claim.reference"
											:disabled="pageLoading"
											:loading="pageLoading"
										>
										</v-text-field>
									</v-col>
								</v-row>
								<v-row class="mb-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label required">
											Title
											<v-tooltip top :content-class="`custom-top-tooltip`">
												<template v-slot:activator="{ on, attrs }">
													<v-icon class="ml-2 mdi-16px" v-on="on" v-bind="attrs">mdi-information-outline</v-icon>
												</template>
												<span class="text-center"
													>For personal Uses - <br />if billable Name can be added to<br />
													invoice long description</span
												>
											</v-tooltip>
										</label>
									</v-col>
									<v-col lg="8" class="my-auto py-0">
										<v-text-field
											hide-details
											outlined
											placeholder="Title"
											v-model="claim.billable_name"
											:rules="[vrules.required(claim.billable_name, 'Title')]"
											:class="{
												required: !claim.billable_name,
											}"
										>
										</v-text-field>
									</v-col>
								</v-row>
								<v-row class="mb-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label"> Shop Name </label>
									</v-col>
									<v-col lg="8" class="my-auto py-0">
										<v-text-field hide-details outlined placeholder="Shop Name" v-model="claim.shop_name">
										</v-text-field>
									</v-col>
								</v-row>
								<v-row class="mb-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label required">Expense Category</label>
									</v-col>
									<v-col lg="8" class="my-auto py-0">
										<v-autocomplete
											:items="expenseList"
											item-text="category"
											item-value="category"
											hide-details
											outlined
											placeholder="Expense Category"
											v-model="claim.expense_category"
											:disabled="pageLoading"
											:loading="pageLoading"
											:rules="[vrules.required(claim.expense_category, 'Expense Category')]"
											:class="{
												required: !claim.expense_category,
											}"
											@change="updateAvailableAmount"
											content-class="rounded-lg"
											:menu-props="{ maxWidth: '400px' }"
										>
											<template #selection="data">
												<span class="fw-500"
													>{{ data.item.category }}
													<!-- (category), {{ data.item.allocated_amount }} (Total),
													{{ data.item.taken }} (claimed), {{ data.item.balance }} (Balance) -->
												</span>
											</template>
											<template #item="{ item }">
												<div class="border-bottom py-2">
													<div class="text-uppercase fw-600 blue--text mb-1">
														<span>{{ item.category }}</span>
														<em v-if="item.duration_type" class="ml-2 grey--text text-capitalize"
															>({{ item.duration_type }})</em
														>
													</div>
													<v-list-item-subtitle>
														<span class="mr-5 fw-600">
															<v-chip color="green" outlined small class="m-1 px-2">
																{{ item.allocated_amount }}
															</v-chip>
															Total
														</span>
														<span class="mr-5 fw-600">
															<v-chip color="orange" outlined small class="ml-1 px-2">
																{{ item.taken }}
															</v-chip>
															Claimed
														</span>
														<span class="mr-5 fw-600">
															<v-chip color="red" outlined small class="ml-1 px-2">
																{{ item.balance }}
															</v-chip>
															Balance
														</span>
													</v-list-item-subtitle>
												</div>
											</template>
										</v-autocomplete>
										<!-- <ManageAutocomplete
											title="Expense Category"
											typeId="13"
											v-on:update="getClaimSetting"
										></ManageAutocomplete> -->
									</v-col>
								</v-row>
								<v-row class="mb-4" v-if="is_subcategory_active">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label required">Expense Sub Category</label>
									</v-col>
									<v-col lg="8" class="my-auto py-0">
										<v-autocomplete
											:items="expenseSubList"
											item-text="text"
											item-value="text"
											hide-details
											outlined
											placeholder="Expense Sub Category"
											v-model="claim.expense_sub_category"
											:disabled="pageLoading"
											:loading="pageLoading"
											:rules="[vrules.required(claim.expense_sub_category, 'Expense Sub Category')]"
											:class="{
												required: !claim.expense_sub_category,
											}"
										/>
									</v-col>
								</v-row>
								<v-row class="mb-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label required">Expense Date</label>
									</v-col>
									<v-col lg="8" class="my-auto py-0">
										<DatePicker
											hide-details
											clearable
											:disabled="pageLoading"
											:loading="pageLoading"
											id="expense-date"
											placeholder="Expense Date"
											v-model="claim.expense_date"
											:rules="[vrules.required(claim.expense_date, 'Expense Date')]"
											:class="{
												required: !claim.expense_date,
											}"
										></DatePicker>
									</v-col>
								</v-row>
								<v-row class="mb-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label required"> Amount </label>
									</v-col>
									<v-col lg="8" class="my-auto py-0">
										<v-text-field
											hide-details
											outlined
											type="number"
											placeholder="Amount"
											v-model.number="claim.amount"
											:rules="[
												vrules.required(claim.amount, 'Amount'),
												(v) => (v <= availableAmount ? true : false) || 'No Available Amount',
											]"
											:class="{
												required: !claim.amount,
											}"
											@keypress="limitDecimal($event, claim.amount)"
											@paste="limitDecimal($event, claim.amount)"
										>
										</v-text-field>
										<div>
											Available Amount : <span class="fw-600">{{ availableAmount }}</span>
										</div>
									</v-col>
								</v-row>
								<v-row class="pb-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label"> Currency </label>
									</v-col>
									<v-col lg="8" class="my-auto py-0">
										<v-autocomplete
											:items="currencyList"
											item-text="text"
											item-value="value"
											hide-details
											outlined
											placeholder="Currency"
											v-model="claim.currency"
										>
										</v-autocomplete>
									</v-col>
								</v-row>
								<v-row class="mb-4">
									<v-col lg="4" cols="12" class="py-0">
										<label class="input-label">
											Note
											<v-tooltip top :content-class="`custom-top-tooltip`">
												<template v-slot:activator="{ on, attrs }">
													<v-icon class="ml-2 mdi-16px" v-on="on" v-bind="attrs">mdi-information-outline</v-icon>
												</template>
												<span class="text-center"
													>If billable Note can<br />
													be added to invoice<br />
													long description</span
												>
											</v-tooltip>
										</label>
									</v-col>
									<v-col lg="8" class="my-auto py-0">
										<v-textarea
											outlined
											placeholder="note"
											v-model="claim.notes"
											rows="3"
											counter="250"
											:rules="[(v) => String(v).length <= 250 || `Maximum character limit 250.`]"
										>
										</v-textarea>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
						<div class="form-grp-title">Advance Option</div>
						<v-row class="px-4">
							<v-col cols="12">
								<v-row class="pb-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label"> Payment Mode </label>
									</v-col>
									<v-col lg="8" class="my-auto py-0 pr-10 position-relative">
										<v-autocomplete
											:items="paymentList"
											item-text="text"
											item-value="value"
											hide-details
											outlined
											placeholder="Payment Mode"
											v-model="claim.payment_mode"
										>
										</v-autocomplete>
										<ManageAutocomplete title="Payment Mode" typeId="14" v-on:update="getClaimSetting">
										</ManageAutocomplete>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
						<v-row class="px-4">
							<v-col cols="12">
								<v-row class="pb-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label"> Attachments </label>
									</v-col>
									<v-col lg="8" class="my-auto py-0">
										<template v-for="(file, index) in files">
											<v-row class="mt-2" :key="index">
												<v-col lg="5" cols="12" class="my-auto py-0 d-flex align-items-center">
													<v-file-input
														:id="`document-file-${index}`"
														placeholder="Select File"
														outlined
														prepend-icon=""
														prepend-inner-icon="mdi-attachment"
														hide-details
														v-model="file.file"
														v-on:change="updateFile(index, $event)"
														v-on:click:clear="updateFile(index, $event)"
													></v-file-input>
												</v-col>
												<v-col lg="5" cols="12" class="my-auto py-0 d-flex align-items-center">
													<TextInput
														:id="`document-name-${index}`"
														hide-top-margin
														v-model="file.name"
														hide-details
														placeholder="File Name"
														:suffix="file.suffix"
													>
													</TextInput>
												</v-col>
												<v-col
													lg="2"
													cols="12"
													class="pl-0 my-auto py-0"
													style="display: grid; grid-template-columns: repeat(2, 1fr)"
												>
													<v-icon
														:disabled="files.length < 2"
														v-on:click="removeFile(index)"
														color="red lighten-1 white--text px-0"
														class="ml-1"
														>mdi-delete
													</v-icon>
													<div>
														<v-icon
															color="blue darken-4 white--text"
															v-if="index == files.length - 1"
															class="ml-2"
															:disabled="files.length > 4"
															depressed
															:style="{
																border: `1px solid ${files.length > 4 ? 'rgba(0, 0, 0, 0.38)' : '#0d47a1'}`,
																borderRadius: '50px',
															}"
															v-on:click="addMore()"
															>mdi-plus
														</v-icon>
													</div>
												</v-col>
											</v-row>
										</template>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-form>
		</div>
	</v-sheet>
</template>
<style>
div#list-78 {
	width: 810px !important;
}
</style>
<script>
import ManageAutocomplete from "@/view/pages/leads/components/ManageAutocomplete";
import { GET, POST, QUERY } from "@/core/services/store/request.module";
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import MainMixin from "@/core/mixins/main.mixin.js";
import { mapGetters } from "vuex";
import DatePicker from "@/view/components/DatePicker";
import TextInput from "@/view/components/TextInput";
import { getCurrentUser } from "@/core/services/jwt.service";
import { toSafeInteger, toString,cloneDeep } from "lodash";
import RadioInputCustom from "@/view/components/RadioInputCustom";
import MomentJS from "moment-timezone";
import objectPath from "object-path";

export default {
	mixins: [MainMixin],
	name: "Create-Claims",
	data() {
		return {
			all_list: {
				customers: [],
				projects: [],
				meetings: [],
			},
			loading: false,
			formValid: true,
			images: [],
			userList:[],
			readers: [],
			pageLoading: false,
			date_menu: false,
			claimId: 0,
			expenseList: [],
			availableAmount: 0,
			expenseSubList: [],
			projectList: [],
			meetingList: [],
			customerList: [],
			leadList: [],
			currencyList: [],
			paymentList: [],
			maxDate:new Date().toISOString().substr(0, 10),
			is_subcategory_active: false,
			files: [
				{
					file: null,
					name: null,
				},
			],
			claim: {
				category: "owan",
				claim_month: "current",
				added_at: null,
				expense_date: new Date().toISOString().substr(0, 10),
				customer: 0,
				reference: null,
				assign:0,
				billable_name: null,
				shop_name: null,
				expense_category: null,
				expense_sub_category: null,
				project: 0,
				amount: 0,
				currency: "sgd",
				notes: null,
				payment_mode: null,
				claim_type: "lead",
				claim_date: null,
				user: 0,
			},
		};
	},
	components: {
		ManageAutocomplete,
		DatePicker,
		TextInput,
		RadioInputCustom,
	},
	methods: {
		getSettings() {
			const _this = this;
			_this.$store
				.dispatch(GET, { url: "lead-setting" })
				.then((data) => {
					let statusData = cloneDeep(data.status);
					statusData.shift(1);
					_this.statusList = statusData;
					_this.sourceList = data.source;
					_this.positonList = data.positon;
					_this.opportunityList = data.opportunity;
					_this.designationList = data.designation;
					_this.productList = data.product_type;
					_this.countryList = data.country;

					if (Array.isArray(data.users) && data.users.length) {
						_this.userList = data.users.filter((user) => user.users_type == "sales");
					}
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		goBackListing() {
			let query = this.$route.query;
			this.$router.push({
				name: "claims",
				query: { ...query, t: new Date().getTime() },
			});
		},
		filterList(module = "", filterBy = "customer") {
			if (!module) return;
			let _this = this;
			let claim_type = _this.claim.claim_type;
			let firstList = _this.all_list;

			switch (module) {
				case "meeting":
					if (firstList.meetings) {
						_this.meetingList = firstList.meetings.map((row) => {
							if (row && row.related_to && row.related_to == claim_type) {
								//&& row.related_id
								//if( _this.claim.customer ==row.related_id )
								if (filterBy == "customer") {
									if (_this.claim.customer == row.related_id) return row;
								}
								return row;
							}
						});
					}
					break;

				case "project":
					//if(claim_type=='customer'){
					//console.log(firstList.projects,'etsts');
					if (firstList.projects) {
						_this.projectList = firstList.projects.map((row) => {
							if (row && row.customer) {
								//&& row.related_id
								if (_this.claim.customer == row.customer) return row;
							}
						});
					}

					//}
					break;
			}
		},
		limitDecimal($event, value) {
			let string = toString(value);
			let keyCode = $event.keyCode ? $event.keyCode : $event.which;

			if (
				((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || string.indexOf(".") != -1)) ||
				string.length > 11
			) {
				$event.preventDefault();
			}

			if (string != null && string.indexOf(".") > -1 && string.split(".")[1].length > 1) {
				$event.preventDefault();
			}
		},
		pageTitle() {
			if (this.uuid) {
				return this.lead.display_name + " - " + this.barcode;
			}
			if (this.claimId > 0) {
				return "Update Claim";
			}
			return "Create new Claims";
		},
		selectFile() {
			this.$refs["uploadFile"].$refs["input"].click();
		},
		updateFile(index, file) {
			if (file && file.name) {
				this.files[index].name = file.name.split(".").slice(0, -1).join(".");
				this.files[index].suffix = `.${file.name.split(".").pop()}`;
			} else {
				this.files[index].name = null;
				this.files[index].suffix = null;
			}
		},
		addMore() {
			this.files.push({
				file: null,
				name: null,
			});
		},
		updateAvailableAmount() {
			this.$nextTick(() => {
				const _category = this.claim.expense_category;
				const _selectCategory = this.expenseList.find((row) => row.category == _category);
				console.log(this.claim.amount,'this.claim')
				if (_selectCategory &&_category ) {
					if(this.$route.params.id){
						this.availableAmount = _selectCategory.balance +this.claim.amount;

					}
					else{
						this.availableAmount = _selectCategory.balance;

					}
				} else {
					this.availableAmount = 0;
				}
				this.$refs["claimForm"].validate();

				if (_category && _category.toLowerCase() == "transport") {
					this.is_subcategory_active = true;
				} else {
					this.is_subcategory_active = false;
				}
			});
		},
		removeFile(index) {
			this.files.splice(index, 1);
			if (this.files.length < 1) {
				this.addMore();
			}
		},
		getClaimSetting() {
			this.$store
				.dispatch(GET, {
					url: `claim-setting`,
				})
				.then((data) => {
					/* 	this.expenseList = data.expense_category; */
					this.paymentList = data.payment_mode;
					this.currencyList = data.currency;
					this.customerList = data.customers;
					this.leadList = data.all_leads;
					this.all_list.customers = this.customerList;
					this.all_list.leads = this.leadList;
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		createOrUpdate() {
			const formErrors = this.validateForm(this.$refs["claimForm"]);
			if (formErrors.length) {
				this.$store.commit(SET_ERROR, this.errors.concat(formErrors));
				return false;
			}

			this.pageLoading = true;
			let formData = new FormData();

			formData.append("category", this.claim.category);

			if (objectPath.get(this.claim, "expense_sub_category")) {
				formData.append("sub_category", this.claim.expense_sub_category);
			}

			if (objectPath.get(this.claim, "project")) {
				formData.append("project", this.claim.project);
			}

			/* if (this.claim && this.claim.customer) { */
			formData.append("customer", this.claim.customer ? this.claim.customer : 0);
			/* 	} */

			if (objectPath.get(this.claim, "reference")) {
				formData.append("reference", this.claim.reference);
			}

			if (objectPath.get(this.claim, "billable_name")) {
				formData.append("billable_name", this.claim.billable_name);
			}
			if (objectPath.get(this.claim, "shop_name")) {
				formData.append("shop_name", this.claim.shop_name);
			}
			formData.append(
				"expense_category",
				this.claim.expense_category ? this.claim.expense_category : 0
			);
			formData.append("meeting", this.claim.meeting ? this.claim.meeting : 0);
			formData.append("expense_date", this.claim.expense_date);

			formData.append("claim_type", this.claim.claim_type);

			formData.append("amount", this.claim.amount ? this.claim.amount : 0);

			formData.append("currency", this.claim.currency);
			formData.append("assign", this.claim.assign);
			// formData.append("claim_date", this.claim.claim_date);

			if (this.claim.claim_date) {
				formData.append("claim_date", this.claim.claim_date);
			}
			

			if (objectPath.get(this.claim, "payment_mode")) {
				formData.append("payment_mode", this.claim.payment_mode);
			}

			if (objectPath.get(this.claim, "claim_month")) {
				formData.append("claim_month", this.claim.claim_month);
			}

			if (this.files && this.files.length) {
				for (let i = 0; i < this.files.length; i++) {
					if (objectPath.get(this.files, `${i}.file`)) {
						formData.append(`file[${i}][file]`, this.files[i].file);
					}
					if (objectPath.get(this.files, `${i}.name`)) {
						formData.append(`file[${i}][name]`, this.files[i].name);
					}
				}
			}

			let requestTYPE = POST;
			let requestURL = "claim";

			/* while update */
			if (this.claimId > 0) {
				requestURL = `claim/${this.claimId}`;
			}

			this.$store
				.dispatch(requestTYPE, {
					url: requestURL,
					data: formData,
				})
				.then(() => {
					if (this.claimId > 0) {
						this.$store.commit(SET_MESSAGE, [
							{
								model: true,
								message: "Success ! Claim updated successfully.",
							},
						]);

						this.claim.customer = "";
						this.claim.reference = null;
						this.claim.billable_name = "";
						this.claim.shop_name = "";
						this.claim.expense_category = "";
						this.claim.expense_date = "";
						this.claim.amount = "";
						this.claim.currency = "";
						this.claim.notes = "";
						this.claim.payment_mode = "";
						this.claim.user = 0;
						this.$router.push("/claims");
					} else {
						this.$store.commit(SET_MESSAGE, [
							{
								model: true,
								message: "Success ! Claim created successfully.",
							},
						]);
						this.claim.customer = "";
						this.claim.reference = null;
						this.claim.billable_name = "";
						this.claim.shop_name = "";
						this.claim.expense_category = "";
						this.claim.expense_date = "";
						this.claim.amount = "";
						this.claim.currency = "";
						this.claim.notes = "";
						this.claim.payment_mode = "";
						this.claim.user = 0;
						/* 	this.$router.push("/claims"); */
						this.$router.replace({
							name: "claims",
							query: { t: new Date().getTime() },
						});
					}
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getCategoryOptions(claim_month = this.claim.claim_month) {
			let _month = null;
			

			let updateCheck =
				this.claimId > 0 ? new Date(this.claim.added_at).getDate() <= 10 : new Date().getDate() <= 10;

			if (updateCheck && claim_month == "previous") {
				_month = MomentJS(this.today).subtract(1, "months").format("YYYY-MM-DD");
			} else {
				_month = this.today;
			}

			const _this = this;
			if(this.claim.assign){
				this.id = this.claim.assign
			}
			else{
				this.id = this.is_staff

			}
			let _date = null;
			if(this.claim.claim_date){
			
				_date = this.claim.claim_date
			}
			_this.pageLoading = true;
			_this.$store
				.dispatch(QUERY, {
					url: "setting/claim/option/" + this.id,
					data: { expense_date: _month, claim_month ,_date},
				})
				.then((data) => {
					_this.expenseList = data.claim_categories;
					_this.expenseSubList = data.claim_sub_categories;

					_this.all_list.projects = data?.projects;
					_this.all_list.meetings = data?.all_meetings;

					_this.filterList("meeting");
					_this.filterList("project");

					_this.$nextTick(() => {
						this.updateAvailableAmount();
					});
				})
				.catch((error) => {
					_this.logError(error);
				})
				.finally(() => {
					_this.pageLoading = false;
				});

			/* 	this.categoryDays(); */
		},
		getClaims() {
			const _this = this;
			this.$store
				.dispatch(GET, {
					url: `single-claim/${this.claimId}`,
				})
				.then((data) => {
					if (Object.keys(data).length) {
						this.claim.category = data.claim.category;
						this.claim.claim_month = data.claim.claim_month;
						this.claim.customer = data.claim.customer;
						this.claim.reference = data.claim.reference || null;
						this.claim.billable_name = data.claim.billable_name;
						this.claim.shop_name = data.claim.shop_name;
						this.claim.expense_category = data.claim.expense_category;
						this.claim.expense_date = data.claim.expense_date;
						this.claim.amount = data.claim.amount;
						this.claim.currency = data.claim.currency;
						this.claim.notes = data.claim.notes;
						this.claim.payment_mode = data.claim.payment_mode;
						this.claim.project = data.claim.project;
						this.claim.expense_sub_category = data.claim.sub_category;
						this.claim.meeting = data?.claim?.meeting;
						this.claim.user = data?.claim?.user;
						this.claim.added_at = data?.claim?.added_at;
						_this.claim.claim_type = data.claim.claim_type ? data?.claim?.claim_type : "lead";
						this.getCategoryOptions(this.claim.claim_month);
						// if(_this.claim.claim_type == "customer"){
						// 	_this.filterList('project');
						// }
					}
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},

		getUser() {
			
			this.getCategoryOptions();
		},

		
		currentUserData() {
			const user = getCurrentUser();
			return user;
		},
		async init() {
			if (this.claimId) {
				this.$store.dispatch(SET_BREADCRUMB, [
					// { text: "Dashboard", disabled: false, href: "/dashboard" },
					{ text: "Claims", disabled: false, href: "/claims" },
					{ text: "Update", disabled: true },
				]);
				await this.getClaims();
			} else await this.getCategoryOptions();
		},
	},
	mounted() {
		this.getClaimSetting();
		if (!this.getPermission("claim:create") && this.claimId == 0) {
			this.$router.replace({
				name: "claims",
				query: { t: new Date().getTime() },
			});
		}
		if (!this.getPermission("claim:update") && this.claimId > 0) {
			this.$router.replace({
				name: "claims",
				query: { t: new Date().getTime() },
			});
		}
		this.getSettings();
		// alert(this.currentUser.id)
		if(this.currentUser.id == 35){
			this.claim.assign = 35;


			
		}
		this.init();
		this.$store.dispatch(SET_BREADCRUMB, [
			// { text: "Dashboard", disabled: false, href: "/dashboard" },
			{ text: "Claims", disabled: false, href: "/claims" },
			{ text: this.claimId > 0 ? "Update" : "Create", disabled: true },
		]);
	},
	beforeMount() {
		this.claimId = toSafeInteger(this.$route.params.id);
	},
	computed: {
		...mapGetters(["errors"]),
		is_staff() {
			const user = this.currentUserData();
			const userId = user.id;
			return userId;
		},
		disableClaimMonth: {
			get() {
				if (this.claimId > 0) {
					if (new Date(this.claim.added_at).getDate() > 10) {
						return true;
					} else {
						return false;
					}
				} else {
					if (new Date().getDate() > 10) {
						return true;
					} else {
						return false;
					}
				}
			},
		},
	},
};
</script>
